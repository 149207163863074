import useScript from "../hooks/useScript";

function App() {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="justify-self-auto">
        <div className="h-6 w-6 bg-blue-500"></div>
        <div className="border-b-2 border-slate-200">
          <h1 className="text-3xl text-blue-500">Hi I'm Kevin</h1>
        </div>
        <ul className="flex flex-col justify-center">
        <li className="text-xl text-blue-500">I'm a software engineer</li>
          <li className="text-xl text-blue-500">
            <a className="hover:border-b-2 hover:border-dashed" target="_blank" href="https://www.linkedin.com/in/kevin-kipta-b666463b/" rel="noreferrer">my linkedin</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default App;
